import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TalukaList from './TalukaList';

const Taluka = () => {
  const [talukas, setTalukas] = useState([]);
  
  useEffect(() => {
    const localStorageTalukas = localStorage.getItem('taluka_data');
    if (localStorageTalukas) {
      setTalukas(JSON.parse(localStorageTalukas));
    }
  }, []);
  
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-semibold">Taluka Master</h3>
          <Link to="/taluka-add">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Add Taluka
            </button>
          </Link>
        </div>
        
        <div className="bg-white p-6 rounded shadow-md">
          <TalukaList talukas={talukas} />
        </div>
        
      </div>
    </div>
  );
};

export default Taluka;
