import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogList from './LogList';

const Log = () => {
  const [log_data, setLog_data] = useState([]);
  
  useEffect(() => {
    const localStoragelogdata = localStorage.getItem('log_data');
    if (localStoragelogdata) {
        setLog_data(JSON.parse(localStoragelogdata));
    }
  }, []);
  
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-semibold">Log Data</h3>
          <Link to="/log-add">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Add Attendance
            </button>
          </Link>
        </div>
        
        <div className="bg-white p-6 rounded shadow-md">
          <LogList talukas={log_data} />
        </div>
        
      </div>
    </div>
  );
};

export default Log;
