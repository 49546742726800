import React from 'react';

const Footer = () => {
    return (
      <div className="bg-[#4E9C46] text-white p-4">
        <div className="container mx-auto text-center sm:text-left">
          <span>Copyright © 2023 Adhyapak App</span>
        </div>
      </div>
    );
  };
  

export default Footer;
