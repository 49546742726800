import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';




const TalukaAdd = () => {
    const navigate = useNavigate();
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Preventing empty name submissions
    if (!name.trim()) {
        alert('Please enter a name.');
        return;
    }
    // Preparing FormData
    const formData = new FormData();
    formData.append('action', 'add_taluka');
    formData.append('name', name.trim());
    formData.append('status', status);

    try {
        // Send POST request
        const response = await axios.post('http://adhyapak-app.test/', formData, {
          headers: {
            'Authorization': 'Bearer youarenotallowed',
          },
        });
        
        if (response.status === 200) {
          console.log("Taluka added successfully:", response.data);
          
          const formData2 = new FormData();
          formData2.append('action', 'get_app_data');
          try {
            // Send POST request
            const response = await axios.post('http://adhyapak-app.test/', formData2, {
              headers: {
                'Authorization': 'Bearer youarenotallowed'
              }
            });
      
            if (response.status === 200) { 
              console.log("App information received:", response.data);
              localStorage.setItem('taluka_data', response.data.taluka_data);
              navigate('/taluka');

      
            } else {
              console.log(`Failed to fetch App info: ${response.data}`);
            }
      
          } catch (error) {
            console.log('An error occurred while fetching app info:', error);
          }



          // Optionally: Update LocalStorage or State to reflect the new taluka
          // Or navigate back to the list page where the newly added taluka will be fetched and displayed.
  
          // Clear the form
          setName('');
          setStatus('active');
  
          // User Feedback
          alert('Taluka added successfully!');
        } else {
          console.log(`Failed to add Taluka: ${response.data}`);
          alert(`Failed to add Taluka: ${response.data}`);
        }
        
      } catch (error) {
        console.log('An error occurred while adding taluka:', error);
        alert('An error occurred while adding taluka. Please try again.');
      }

  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        
        <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
          <h3 className="text-2xl font-semibold mb-6">Add New Taluka</h3>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            
            <div className="mb-6">
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="status"
                    value="active"
                    checked={status === 'active'}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  <span className="ml-2">Active</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="status"
                    value="inactive"
                    checked={status === 'inactive'}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  <span className="ml-2">Inactive</span>
                </label>
              </div>
            </div>
            
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TalukaAdd;
