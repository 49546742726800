import React, { useState, useEffect } from 'react';
import jsonData from '../master.json';

// import jsonData from './master.json'; // Importing local JSON file
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';




const LogAdd = () => {
    // const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [selectedTaluka, setSelectedTaluka] = useState('');
    const [selectedCluster, setSelectedCluster] = useState('');
    const [selectedTown, setSelectedTown] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [numberOfAttendees, setNumberOfAttendees] = useState(''); 
    const [photo, setPhoto] = useState(null); 
    
    const [errors, setErrors] = useState([]); // State for error messages

    const handleFileChange = (event) => {
        // Get the selected file
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
          setPhoto(file);
        } else {
          setErrors((prevErrors) => [...prevErrors, 'Invalid file. Please select an image.']);
        }
      };

    // Mocked fetching data from JSON file.
  // Replace this with actual logic to fetch data from 'master.json'
  useEffect(() => {
    // Set local JSON data to state
    setData(jsonData);
  }, []);

  // Derived state
  const clusters = selectedTaluka ? selectedTaluka.clusters : [];
  const towns = selectedCluster ? selectedCluster.towns : [];
  const schools = selectedTown ? selectedTown.schools : [];

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errorMessages = [];

 // Validate form inputs
 if (!selectedTaluka) errorMessages.push('Taluka is required.');
 if (!selectedCluster) errorMessages.push('Cluster is required.');
 if (!selectedTown) errorMessages.push('Town is required.');
 if (!selectedSchool) errorMessages.push('School is required.');
 if (!selectedClass) errorMessages.push('Class is required.');
 if (!selectedSubject) errorMessages.push('Subject is required.');
 if (!numberOfAttendees || numberOfAttendees < 1) errorMessages.push('Number of Attendees should be at least 1.');
 if (!photo) errorMessages.push('A photo is required.');

 // Set errors state if there are error messages
 if (errorMessages.length > 0) {
   setErrors(errorMessages);
   return;
 }
    
    
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        
        <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
          <h3 className="text-2xl font-semibold mb-6">Add Attendance</h3>
          
          <form onSubmit={handleSubmit}>
            
          {errors.length > 0 && (
              <div className="mb-4 text-red-600">
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            
          
          <div className="mb-4">
    <label htmlFor="classes" className="block text-sm font-medium text-gray-700">
      Taluka
    </label>

        <select onChange={(e) => {
            setSelectedTaluka(data.find(taluka => taluka.taluka === e.target.value));
            setSelectedCluster(null); // Reset Cluster when Taluka is changed
            setSelectedTown(null); // Reset Town when Taluka is changed
            setSelectedSchool(null); // Reset School when Taluka is changed
        }}
        // onChange={(e) => setSelectedTaluka(data.find(taluka => taluka.taluka === e.target.value))}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Select Taluka</option>
          {data.map((taluka) => (
            <option key={taluka.taluka} value={taluka.taluka}>
              {taluka.taluka}
            </option>
          ))}
        </select></div>

        <div className="mb-4">
    <label htmlFor="classes" className="block text-sm font-medium text-gray-700">
      Cluster
    </label>
        <select 
        onChange={(e) => {
            setSelectedCluster(clusters.find(cluster => cluster.cluster === e.target.value));
            setSelectedTown(null); // Reset Town when Cluster is changed
            setSelectedSchool(null); // Reset School when Cluster is changed
        }}
        // onChange={(e) => setSelectedCluster(clusters.find(cluster => cluster.cluster === e.target.value))}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Select Cluster</option>
          {clusters.map((cluster) => (
            <option key={cluster.cluster} value={cluster.cluster}>
              {cluster.cluster}
            </option>
          ))}
        </select>
        </div>

        <div className="mb-4">
    <label htmlFor="classes" className="block text-sm font-medium text-gray-700">
      Town
    </label>
        <select 
         onChange={(e) => {
            setSelectedTown(towns.find(town => town.town === e.target.value));
            setSelectedSchool(null); // Reset School when Town is changed
        }}
        // onChange={(e) => setSelectedTown(towns.find(town => town.town === e.target.value))}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Select Town</option>
          {towns.map((town) => (
            <option key={town.town} value={town.town}>
              {town.town}
            </option>
          ))}
        </select>
        </div>

        <div className="mb-4">
    <label htmlFor="classes" className="block text-sm font-medium text-gray-700">
      School
    </label>
        <select onChange={(e) => setSelectedSchool(e.target.value)}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="">Select School</option>
          {schools.map((school) => (
            <option key={school} value={school}>
              {school}
            </option>
          ))}
        </select>
        </div>

        <div className="mb-4">
    <label htmlFor="classes" className="block text-sm font-medium text-gray-700">
      Class
    </label>
    <select
      name="classes"
      id="classes"
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      value={selectedClass}
      onChange={(e) => setSelectedClass(e.target.value)}
    >
      <option value="">Select Class</option>
      {[...Array(10).keys()].map((num) => (
        <option key={num + 1} value={num + 1}>
          {num + 1}
        </option>
      ))}
    </select>
  </div>
  
  <div className="mb-4">
    <label htmlFor="commonSubjects" className="block text-sm font-medium text-gray-700">
      Subjects
    </label>
    <select
      name="commonSubjects"
      id="commonSubjects"
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      value={selectedSubject}
      onChange={(e) => setSelectedSubject(e.target.value)}
    >
      <option value="">Select Subject</option>
      
      <option value="Math">Math</option>
      <option value="Science">Science</option>
      <option value="English">English</option>
      <option value="Marathi">Marathi</option>
      <option value="Hindi">Hindi</option>
      <option value="Sanskrit">Sanskrit</option>
      <option value="History">History</option>
      <option value="Geography">Geography</option>
      <option value="Civics">Civics</option>
      <option value="Environmental Studies">Environmental Studies</option>
      
    </select>
  </div>
      
  <div className="mb-4">
              <label 
                htmlFor="numberOfAttendees" 
                className="block text-sm font-medium text-gray-700"
              >
                Number of Attendees
              </label>
              <input 
                type="number" 
                id="numberOfAttendees" 
                name="numberOfAttendees"
                min="1"
                value={numberOfAttendees} 
                onChange={(e) => setNumberOfAttendees(Number(e.target.value))}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                Take a Photo
              </label>
              <input
                type="file"
                id="photo"
                name="photo"
                accept="image/*"
                capture="environment"
                onChange={handleFileChange}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LogAdd;
