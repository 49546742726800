import React from 'react'

const StudyMaterials = () => {
  return (
    <div>
      <center>
      <h2>
        Study Materials to be Added here
      </h2></center>
    </div>
  )
}

export default StudyMaterials
